import type { NextPage } from "next";
import { useEffect, useState } from "react";
import Logo from "./../public/svg/parkableLogo.svg";
import styles from "./index.module.scss";
import Image from "next/image";
import { VERCEL_URL } from "../config/vercel.config";

const Home: NextPage = () => {
    const [initialised, setInitialised] = useState(false);

    //importing rapi-doc library for TypeScript
    useEffect(() => {
        if (!initialised) {
            require("rapidoc").default;
        }
        setInitialised(true);
    }, []);

    useEffect(() => {
        if (typeof window !== "undefined") {
            const rapiDocs = document.getElementsByTagName("rapi-doc")[0];
            const style = document.createElement("style");
            document.title = "Parkable API";
            style.innerHTML = `
                .sub-title {
                    display: none
                }
                .title.tag {
                    text-transform: none !important;
                }
                h3 {
                    font-size: 32px !important; font-weight: 700 !important; text-transform: none !important;
                }
                .nav-bar-section-title {
                    font-weight: 400 !important
                }
                .m-markdown {
                    padding-bottom: 10px
                }
                .m-markdown.regular-font {
                    padding-top: 10px
                }
                #api-info {
                    font-size: 10px !important
                }`;
            rapiDocs?.shadowRoot?.appendChild(style);
        }
    });

    return (
        <>
            {initialised && (
                // @ts-ignore
                <rapi-doc
                    spec-url={VERCEL_URL}
                    render-style="focused"
                    style={{ height: "100vh", width: "100%" }}
                    regular-font={"GT Eesti Display"}
                    mono-font={"sans-serif"}
                    font-size={"largest"}
                    theme={"light"}
                    nav-bg-color={"#1F2034"}
                    nav-text-color={"#fff"}
                    primary-color={"#006eb9"}
                    show-header={false}
                    show-components={true}
                >
                    <span className={styles.logo}>
                        <Image src={Logo} alt={"Parkable Logo"} priority />
                    </span>
                    <div slot={"servers"} className={styles.serversHeader}>
                        API server
                    </div>
                    <div slot={"auth"} className={styles.authHeader}>
                        Authentication
                    </div>
                    {/* @ts-ignore */}
                </rapi-doc>
            )}
        </>
    );
};

export default Home;
